import { Record, GetManyParams } from 'react-admin'

type StudentData = {
  email?: string
}

type DataPresign = {
  class_id: string
  file: File
}

type DataUpload = {
  url: string
  file: File
  fields: AWSFields
}

export type AWSFields = {
  key: string
  'x-amz-algorithm': string
  'x-amz-credential': string
  'x-amz-date': string
  policy: string
  'x-amz-signature': string
}

type AWSResponse = {
  url: string
  cloudfront_url: string
  fields: AWSFields
}

export interface Student extends Record {
  id: string
  email: string
  form: string
  owner: string
  roles: string
  access: string
  project: string
  state: string
  created: Date
  modified: Date
  date: Date
  data?: StudentData
}

export interface Country extends Record {
  id: string
  name: string
  iso: string
  iso3: string
  numeric_code: string
  latitude: number
  longitude: number
  deleted_at?: Date
  updated_at?: Date
  updated_by?: string
  created_at: string
}

export interface CountryFilters {
  id?: string
  name?: string
  in_use?: boolean
}

export enum RegistrationTypeEnum {
  onSiteRegistration = 'On-Site Registration',
  preRegistration = 'Pre-Registration',
}

export type RegistrationType = {
  id: RegistrationTypeEnum
  name: RegistrationTypeEnum
}

export enum AssessmentStructureEnum {
  continuousAssessment = 'Continuous Assessment',
  nonContinuousAssessment = 'Non-Continuous Assessment',
}

export type AssessmentStructure = {
  id: AssessmentStructureEnum
  name: AssessmentStructureEnum
}

export enum AccreditationEnum {
  AACSB = 'AACSB',
  AMBA = 'AMBA',
  EQUIS = 'EQUIS',
  ABET = 'ABET',
  washingtonAccord = 'Washington Accord',
}

export type Accreditation = {
  id: AccreditationEnum
  name: AccreditationEnum
}

export interface HostInstitution extends Record {
  id: string
  name: string
  city?: string
  registration_type?: RegistrationType
  assessment_structure?: AssessmentStructure
  accreditation?: Accreditation
  shipping_address_line_1?: string
  shipping_address_line_2?: string
  shipping_address_city?: string
  shipping_address_country?: string
  shipping_address_postal_code?: string
  finance_code?: string
  netsuite_internal_account_id?: string
  salesforce_id?: null
  deleted_from_source?: boolean
  country_id?: string
  source_system_id?: string
  credit_unit_id?: string
  country?: Country
  source_system: SourceSystem
  created_at: string
  archived_at?: string
  updated_at?: string
  updated_by?: string
}

export interface HostInstitutionFilters {
  id?: string
  name?: string
  country_id?: string
  credit_unit_id?: string
  city?: string
}

export interface Class extends Record {
  id: string
  source_system_class_id?: string
  title: string
  format: string
  city: string
  country: string
  host_institution_credit_amount: number
  recommended_us_credit_amount: string
  description: string
  level: string[]
  intensive: boolean
  cost?: number
  price?: number
  language_of_instruction?: string
  language_of_level_requirement?: string[]
  prerequisites?: string
  corequisites?: string
  required_texts?: string
  api_url_syllabus?: string
  host_institution_syllabus?: string
  link_text?: string
  contact_hours?: number
  classroom_size?: string
  classroom_peers: string[]
  status: string
  deleted_from_source?: boolean
  host_institution_id: string
  source_system_id?: string
  archived_at?: string
  created_at?: string
  updated_at?: string
  updated_by: string
  host_institution?: HostInstitution
  source_system?: SourceSystem
  terms?: Term[]
  programs?: Program[]
  cip_codes?: CIPCode[]
}

export interface CIPCode extends Record {
  cip_family?: string
  cip_code?: string
  cip_title?: string
  action?: string
  text_change?: string
  cip_definition?: string
  cross_reference?: string
  examples?: string
  archived_at?: string
  created_at?: string
  updated_at?: string
  updated_by?: string
}

export interface Term extends Record {
  id: string
  name: string
  description: string
  session: Session
  archived_at?: string
  created_at?: string
  updated_at?: string
  updated_by?: string
}

export enum Session {
  fall = 'Fall',
  spring = 'Spring',
  summer = 'Summer',
  virtual = 'Virtual',
}

export type SourceSystemName = 'Acadeum' | 'API CMS' | 'CRAFT'

export interface SourceSystem extends Record {
  id: string
  name: SourceSystemName
  archived_at?: string
  created_at: string
  updated_at?: string
  updated_by: string
}

export interface CreditUnit extends Record {
  id: string
  name: string
  calculation: string
  archived_at?: string
  created_at: string
  updated_at?: string
  updated_by?: string
}

export interface GenericFilter {
  q?: string
}

export interface CreditUnitFilters {
  id?: string
  name?: string
}

export interface ProgramFilters {
  name?: string
}

export interface LearningServiceQueryParams {
  skip: number
  limit: number
  order: string
  field: string
}

export interface Program extends Record {
  id: string
  name: string
  salesforce_id?: string
  archived_at?: string
  created_at: string
  updated_at?: string
  updated_by?: string
}

export interface ClassPresignParams {
  data: DataPresign
}

export interface ClassUploadParams {
  data: DataUpload
}

export interface ClassPresignResponse {
  data: AWSResponse
}

export interface GetManyParamsWithPages extends GetManyParams {
  skip?: number
  limit?: number
}

export interface GetListAllParams {
  limit: number
  total: number
}
