import * as React from 'react'
import { List, TextField, ListProps, FilterButton, CreateButton, TopToolbar } from 'react-admin'

import Datagrid from 'components/MaterialUICore/Datagrid'
import Box from '@material-ui/core/Box'

import { DEFAULT_LIMIT } from 'utils/constants'
import { makeStyles } from '@material-ui/styles'
import SearchQueryFilter from 'components/SearchQueryFilter'
import StyledSearchInput from 'components/StyledSearchInput'

const useStyles = makeStyles({
  filters: {
    '& form': {
      alignItems: 'center',
      marginTop: '0 !important',
      width: '100%',
      '& [data-source="name"]': {
        width: '100%',
      },
      '& #city-search-helper-text': {
        display: 'none',
      },
      '& #full-text-search-helper-text': {
        display: 'none',
      },
    },
  },
  filterButton: {
    marginRight: '5px',
    alignSelf: 'center',
  },
})

const CountryFilters = [
  <SearchQueryFilter key="search-query-filter" className="search-query-input" source="Search" alwaysOn />,
  <StyledSearchInput key="search-name-filter" source="name" label="Name" />,
]

const rowClick = () => {
  return 'show'
}

const ListActions: React.FC = () => {
  const classes = useStyles()
  return (
    <Box width="75%">
      <TopToolbar>
        <FilterButton className={classes.filterButton} />
        <CreateButton variant="contained" color="primary" />
      </TopToolbar>
    </Box>
  )
}
const CountryList: React.FC<ListProps> = props => {
  const classes = useStyles()
  return (
    <List
      {...props}
      className={classes.filters}
      perPage={DEFAULT_LIMIT}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={false}
      filters={CountryFilters}
      actions={<ListActions />}
    >
      <Datagrid rowClick={rowClick}>
        <TextField source="name" />
        <TextField source="iso" />
        <TextField source="iso3" />
        <TextField source="numeric_code" />
        <TextField source="latitude" />
        <TextField source="longitude" />
      </Datagrid>
    </List>
  )
}

export default CountryList
