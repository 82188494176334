import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Show, SimpleShowLayout, TextField, DateField, ShowProps } from 'react-admin'

const useStyles = makeStyles({
  root: { width: 600 },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
})

type CountryTitleProps = ShowProps & { record?: any }
const CountryTitle: React.FC<CountryTitleProps> = ({ record }) => {
  return <span>Country: {record.name}</span>
}

type CountryShowProps = ShowProps & { record?: any }
const CountryShow: React.FC<CountryShowProps> = props => {
  const classes = useStyles()

  return (
    <Show className={classes.root} title={<CountryTitle {...props} />} {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="iso" />
        <TextField source="iso3" />
        <TextField source="numeric_code" />
        <TextField source="latitude" />
        <TextField source="longitude" />
        <DateField source="created_at" />
        <DateField source="deleted_at" />
        <DateField source="updated_at" />
        <DateField source="updated_by" />
      </SimpleShowLayout>
    </Show>
  )
}

export default CountryShow
