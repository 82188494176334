import { GetListParams } from 'react-admin'

import { DEFAULT_PAGE, DEFAULT_LIMIT, DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from 'utils/constants'
import { CreditUnitFilters, CountryFilters, GenericFilter } from 'types'

export type QueryParams = {
  page?: number
  perPage?: number
  sortField?: string
  sortOrder?: string
  filter?: CreditUnitFilters | CountryFilters | GenericFilter
}

export const buildQueryParams = ({
  page = DEFAULT_PAGE,
  perPage = DEFAULT_LIMIT,
  sortField = DEFAULT_SORT_FIELD,
  sortOrder = DEFAULT_SORT_ORDER,
  filter = {},
}: QueryParams): GetListParams => {
  return {
    pagination: { page, perPage },
    sort: { field: sortField, order: sortOrder },
    filter,
  }
}
