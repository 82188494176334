import { Class, CIPCode, Term, Program } from 'types'
import { Record } from 'react-admin'

enum FilterKey {
  id = 'id',
  cipCode = 'cip_code',
}

export const filterId = (item: Term | Program | CIPCode, key: FilterKey) => (item instanceof Object ? item[key] : item)

export const filterRelationalData = (data: Class) => {
  const { terms, programs, cip_codes } = data
  return {
    ...data,
    terms: terms?.map((term: Term) => filterId(term, FilterKey.id)),
    programs: programs?.map((program: Program) => filterId(program, FilterKey.id)),
    cip_codes: cip_codes?.map((cipCode: CIPCode) => filterId(cipCode, FilterKey.cipCode)),
  }
}

const castArrayToString = <T>(items: T[], key: keyof T) =>
  items.reduce((current, item) => `${current};${item[key]}`, '').slice(1)

const transformProgramName = (items: Program[]) =>
  items.reduce((current, item) => `${current};${item.name} (${item.salesforce_id})`, '').slice(1)

export const exportClasses = (records: Record[]): any[] =>
  records.map(cls => ({
    'Host Institution Name': cls.host_institution.name,
    City: cls.host_institution.city,
    'Class ID': cls.id,
    'Class Title': cls.title,
    'Class Code': cls.source_system_class_id,
    Status: cls.status,
    Terms: castArrayToString(cls.terms, 'name'),
    'Class Format': cls.format,
    'Host University Credit Amount': cls.host_institution_credit_amount,
    'Class Credit Units': cls.host_institution.credit_unit.name,
    'Recommended US Credit Amount': cls.recommended_us_credit_amount,
    'Class Description': cls.description,
    'Class Level': cls.level.join(';'),
    Intensive: cls.intensive,
    'Class Cost': cls.cost,
    'Lang of Instruction': cls.language_of_instruction,
    'Lang Level Requirement': cls.language_level_requirement,
    Prerequisites: cls.prerequisites,
    Corequisites: cls.corequisites,
    'Required Texts': cls.required_texts,
    'API URL Syllabus': cls.api_url_syllabus,
    'Host University URL Syllabus': cls.host_institution_url_syllabus,
    'Areas of Study': castArrayToString(cls.cip_codes, 'cip_title'),
    'Class Contact Hours': cls.contact_hours,
    'Classroom Size': cls.classroom_size,
    'Classroom Peers': cls.classroom_peers.join(';'),
    'Program Name': transformProgramName(cls.programs),
  }))
