import * as React from 'react'
import {
  SelectInput,
  SelectArrayInput,
  TextInput,
  Loading,
  Error,
  useQuery,
  AutocompleteInput,
  Labeled,
  FormWithRedirect,
  Toolbar,
  required,
  SaveButton,
  ReferenceInput,
  ToolbarProps,
  FormWithRedirectRenderProps,
} from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { Box, Grid, Card, CardContent } from '@material-ui/core'

import { buildQueryParams } from 'utils/queryParams'
import { accreditations, registrationTypes, assessmentStructures } from './hostInstitutionValidation'
import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from 'utils/constants'

const useStyles = makeStyles({
  gridItem: {
    width: '100%',
    padding: '0',
  },
  labeledItem: {
    width: '100%',
    padding: '0',
  },
})

export const HostInstitutionFormToolbar: React.FC<ToolbarProps> = ({ handleSubmitWithRedirect }) => {
  return (
    <Toolbar handleSubmitWithRedirect={handleSubmitWithRedirect}>
      <SaveButton label="Save" redirect="list" submitOnEnter />
    </Toolbar>
  )
}

const HostInstitutionForm: React.FC = props => {
  const classes = useStyles()
  const creditUnitSort = {
    field: DEFAULT_SORT_FIELD,
    order: DEFAULT_SORT_ORDER,
  }

  const {
    data: countries,
    loading: loadingCountries,
    error: errorCountries,
  } = useQuery({
    type: 'getList',
    resource: 'countries',
    payload: buildQueryParams({ perPage: 250, sortField: 'name' }),
  })

  if (loadingCountries) {
    return <Loading />
  }

  if (errorCountries) {
    return <Error error={errorCountries} />
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: FormWithRedirectRenderProps) => (
        <Box maxWidth="100%">
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="name" className={classes.labeledItem}>
                    <TextInput fullWidth source="name" label="Name" placeholder="Enter" validate={[required()]} />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="city" className={classes.labeledItem}>
                    <TextInput fullWidth source="city" placeholder="Enter" label="City" />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="country" className={classes.labeledItem}>
                    <AutocompleteInput
                      source="country_id"
                      choices={countries || []}
                      allowEmpty
                      emptyText="None"
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="Class Credit Units" className={classes.labeledItem} required>
                    <ReferenceInput source="credit_unit_id" reference="credit_units" sort={creditUnitSort}>
                      <AutocompleteInput optionText="name" optionValue="id" fullWidth validate={[required()]} />
                    </ReferenceInput>
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="shipping_address_line_1" className={classes.labeledItem}>
                    <TextInput
                      source="shipping_address_line_1"
                      placeholder="Enter"
                      label="Shipping Address Line 1"
                      fullWidth
                    />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="shipping_address_line_2" className={classes.labeledItem}>
                    <TextInput
                      fullWidth
                      source="shipping_address_line_2"
                      placeholder="Enter"
                      label="Shipping Address Line 2"
                    />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="name" className={classes.labeledItem}>
                    <TextInput
                      source="shipping_address_city"
                      placeholder="Enter"
                      label="Shipping Address City"
                      fullWidth
                    />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="shipping_address_country" className={classes.labeledItem}>
                    <TextInput
                      fullWidth
                      source="shipping_address_country"
                      placeholder="Enter"
                      label="Shipping Address Country"
                    />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="name" className={classes.labeledItem}>
                    <TextInput
                      source="shipping_address_postal_code"
                      placeholder="Enter"
                      label="Shipping Address Postal Code"
                      fullWidth
                    />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="finance_code" className={classes.labeledItem}>
                    <TextInput fullWidth source="finance_code" placeholder="Enter" label="Finance Code" />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="netsuite_internal_account_id" className={classes.labeledItem}>
                    <TextInput
                      fullWidth
                      source="netsuite_internal_account_id"
                      placeholder="Enter"
                      label="Netsuite Internal Account Id"
                    />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="salesforce_id" className={classes.labeledItem}>
                    <TextInput fullWidth source="salesforce_id" placeholder="Enter" label="Salesforce Id" />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="assessment_structure" className={classes.labeledItem}>
                    <SelectInput fullWidth source="assessment_structure" choices={assessmentStructures} />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="registration_type" className={classes.labeledItem}>
                    <SelectInput fullWidth source="registration_type" choices={registrationTypes} />
                  </Labeled>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <Labeled source="accreditation" className={classes.labeledItem}>
                    <SelectArrayInput fullWidth source="accreditation" choices={accreditations} />
                  </Labeled>
                </Grid>
              </Grid>
            </CardContent>
            <HostInstitutionFormToolbar handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
          </Card>
        </Box>
      )}
    />
  )
}

export default HostInstitutionForm
