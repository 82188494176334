import React from 'react'
import { DatagridRowProps, Record } from 'react-admin'
import { ListItemActionMenu } from 'components/ListActions'
import HostInstitutionArchiveConfirmationMessage from './HostInstitutionArchiveConfirmationMessage'

type HostInstitutionItemActionMenuProps = {
  record?: Record
}

const HostInstitutionItemActionMenu = React.forwardRef<
  HTMLInputElement,
  DatagridRowProps & HostInstitutionItemActionMenuProps
>((props, ref) => {
  const { record } = props
  const hostInstitutionRecord = record as Record

  return (
    <ListItemActionMenu
      resource="host_institutions"
      record={hostInstitutionRecord}
      customConfirmationMessage={<HostInstitutionArchiveConfirmationMessage record={hostInstitutionRecord} />}
    />
  )
})

export default HostInstitutionItemActionMenu
