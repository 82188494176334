import {
  AssessmentStructureEnum,
  AssessmentStructure,
  RegistrationType,
  RegistrationTypeEnum,
  AccreditationEnum,
  Accreditation,
} from '../../../types'

export const registrationTypes: RegistrationType[] = [
  {
    id: RegistrationTypeEnum.onSiteRegistration,
    name: RegistrationTypeEnum.onSiteRegistration,
  },
  {
    id: RegistrationTypeEnum.preRegistration,
    name: RegistrationTypeEnum.preRegistration,
  },
]

export const assessmentStructures: AssessmentStructure[] = [
  {
    id: AssessmentStructureEnum.continuousAssessment,
    name: AssessmentStructureEnum.continuousAssessment,
  },
  {
    id: AssessmentStructureEnum.nonContinuousAssessment,
    name: AssessmentStructureEnum.nonContinuousAssessment,
  },
]

export const accreditations: Accreditation[] = [
  { id: AccreditationEnum.AACSB, name: AccreditationEnum.AACSB },
  { id: AccreditationEnum.AMBA, name: AccreditationEnum.AMBA },
  { id: AccreditationEnum.EQUIS, name: AccreditationEnum.EQUIS },
  { id: AccreditationEnum.ABET, name: AccreditationEnum.ABET },
  {
    id: AccreditationEnum.washingtonAccord,
    name: AccreditationEnum.washingtonAccord,
  },
]
