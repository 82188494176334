import HostInstitutionList from './HostInstitutionList'
import HostInstitutionEdit from './HostInstitutionEdit'
import { HostInstitutionIcon } from 'components/Icons'
import HostInstitutionCreate from './HostInstitutionCreate'

const hostInstitutions = {
  list: HostInstitutionList,
  edit: HostInstitutionEdit,
  create: HostInstitutionCreate,
  icon: HostInstitutionIcon,
}

export default hostInstitutions
