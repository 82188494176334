import * as React from 'react'
import {
  SelectInput,
  SelectArrayInput,
  TextInput,
  Loading,
  Error,
  useQuery,
  AutocompleteInput,
  NullableBooleanInput,
  FormWithRedirect,
  Toolbar,
  required,
  SaveButton,
  NumberInput,
  useRecordContext,
  Record,
  Identifier,
  ToolbarProps,
  maxLength,
  minLength,
  Validator,
  FormWithRedirectRenderProps,
} from 'react-admin'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import { Box, Grid, Card, CardContent } from '@material-ui/core'
import { buildQueryParams } from 'utils/queryParams'
import ApiUrlSyllabus from './ApiUrlSyllabus'
import {
  formatChoices,
  levelChoices,
  languageLevelRequirementChoices,
  languageOfInstructionChoices,
  classroomPeersChoices,
  classroomSizeChoices,
  statusChoices,
} from '../ClassBulkEdit/classValidation'
import { CreditUnit, HostInstitution, Class, Program, AWSFields } from 'types'
import { calculateRecommendedUSCreditAmount } from 'utils/functions'
import ProgramSelector from './ProgramSelector'
import CustomAutocomplete from 'components/CustomAutocomplete'
import { getManyWithoutPagination } from 'utils/getManyWithoutPagination'
import * as yup from 'yup'
import { LARGE_PSQL_STRING_LENGTH, DEFAULT_PSQL_STRING_LENGTH } from 'utils/constants'

const useStyles = makeStyles({
  gridItem: {
    width: '100%',
    padding: '0',
  },
  labeledItem: {
    width: '100%',
    padding: '0',
  },
  descriptionField: {
    height: '70px',
    overflow: 'hidden',
  },
  intensiveField: {
    width: '100% !important',
  },
  inputBase: {
    height: '3em',
    width: '100%',
    borderRadius: '5px 5px 0 0',
    textTransform: 'capitalize',
    [`& div[class*='MuiFilledInput-root']`]: {
      minHeight: '3em',
      marginTop: '8px',
      padding: '0',
      [`& div[class*='MuiAutocomplete-tag']`]: {
        margin: '20px 3px 10px 3px',
      },
    },
  },
})

const yupUrlValidator = yup.string().nullable().notRequired().url()
const yupValidator: Validator = value => {
  if (!yupUrlValidator.isValidSync(value)) {
    return 'Please enter a valid URL'
  }
  return null
}
const validateUrl = [minLength(2), maxLength(LARGE_PSQL_STRING_LENGTH), yupValidator]

const parsePrograms = (record: Class): Identifier[] => {
  if (!Array.isArray(record.programs)) {
    return []
  }
  return record.programs.map(program => program.id ?? program)
}

export const ClassFormToolbar: React.FC<ToolbarProps> = ({ handleSubmitWithRedirect }) => {
  return (
    <Toolbar handleSubmitWithRedirect={handleSubmitWithRedirect}>
      <SaveButton label="Save" redirect="list" submitOnEnter data-testid="saveButton" />
    </Toolbar>
  )
}

type ClassFormProps = {
  saveLabel?: string
  onSetEndpoint?: (awsUrl: string) => void
  onSetFile?: (file: File) => void
  onSetFields?: (formFields: AWSFields) => void
  onSetRecord?: (arg: Record) => void
  hideSyllabus?: boolean
}

const ClassForm: React.FC<ClassFormProps> = props => {
  const classes = useStyles()
  const record: Class = useRecordContext()
  const [programs, setPrograms] = React.useState<Array<any>>([])
  const [loadingPrograms, setloadingPrograms] = React.useState(true)
  const [errorPrograms, setErrorPrograms] = React.useState(false)

  const { recommended_us_credit_amount, host_institution, host_institution_credit_amount } = record
  const [recommendedAmount, setRecommendedAmount] = React.useState<string>(recommended_us_credit_amount ?? '')
  const [creditUnitCalculation, setCreditUnitCalculation] = React.useState<string>(
    host_institution?.credit_unit?.calculation ?? '',
  )
  const [hostInstitutionCreditAmount, setHostInstitutionCreditAmount] = React.useState<number>(
    host_institution_credit_amount ?? 0,
  )

  React.useEffect((): void => {
    setRecommendedAmount(calculateRecommendedUSCreditAmount(creditUnitCalculation, hostInstitutionCreditAmount))
  }, [creditUnitCalculation, hostInstitutionCreditAmount])

  const perPage = 250
  const sortOrder = 'ASC'

  const handleHostInstitutionCreditAmount = (e: React.FormEvent<HTMLInputElement>): void => {
    const inputValue = parseFloat(e.currentTarget.value)
    setHostInstitutionCreditAmount(inputValue)
  }

  const handleHostInstitution = (inputValue: string): void => {
    const { credit_unit }: CreditUnit = hostInstitutions.find(
      (hostInstitution: HostInstitution) => hostInstitution.id === inputValue,
    )
    setCreditUnitCalculation(credit_unit.calculation)
  }

  React.useEffect(() => {
    getManyWithoutPagination('programs', parsePrograms(record as Class))
      .then(res => {
        setPrograms(res.data)
      })
      .catch(() => {
        setErrorPrograms(true)
      })
    setloadingPrograms(false)
  }, [record])

  const {
    data: hostInstitutions,
    loading: loadingHostInstitutions,
    error: errorHostInstitutions,
  } = useQuery({
    type: 'getList',
    resource: 'host_institutions',
    payload: buildQueryParams({ perPage, sortField: 'name', sortOrder }),
  })

  const {
    data: terms,
    loading: loadingTerms,
    error: errorTerms,
  } = useQuery({
    type: 'getList',
    resource: 'terms',
    payload: buildQueryParams({ perPage, sortField: 'name', sortOrder }),
  })

  const isLoading = loadingHostInstitutions || loadingTerms || loadingPrograms
  const error = errorHostInstitutions || errorTerms || errorPrograms

  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return <Error error={error} />
  }

  // Dynamic field rendering: API Url Syllabus
  const RenderSyllabus = () => {
    if (!props.hideSyllabus) {
      props.onSetRecord?.(record)
      return (
        <ApiUrlSyllabus
          source="api_url_syllabus"
          onSetEndpoint={props.onSetEndpoint}
          onSetFile={props.onSetFile}
          onSetFields={props.onSetFields}
        />
      )
    }
    return null
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: FormWithRedirectRenderProps) => (
        <Box maxWidth="100%">
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextInput
                    fullWidth
                    source="source_system_class_id"
                    label="Source System Class ID/Class Code"
                    placeholder="Enter"
                    data-testid="sourceSystemClassIdField"
                    validate={[maxLength(DEFAULT_PSQL_STRING_LENGTH)]}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectInput
                    id="statusField"
                    fullWidth
                    source="status"
                    choices={statusChoices}
                    label="Status"
                    placeholder="Select"
                    validate={[required()]}
                    data-testid="statusField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextInput
                    fullWidth
                    source="title"
                    label="Class Title"
                    placeholder="Enter"
                    validate={[required(), maxLength(DEFAULT_PSQL_STRING_LENGTH)]}
                    data-testid="titleField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectArrayInput
                    id="termsField"
                    fullWidth
                    source="terms"
                    choices={terms || []}
                    label="Terms"
                    placeholder="Select"
                    data-testid="termsField"
                    format={(values, _name) => {
                      return values
                        ? values.map(entry => {
                            return entry?.id || entry
                          })
                        : []
                    }}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <AutocompleteInput
                    fullWidth
                    choices={hostInstitutions || []}
                    source="host_institution_id"
                    label="Host University"
                    placeholder="Enter"
                    onChange={handleHostInstitution}
                    validate={[required()]}
                    id="hostInstitutionsField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectInput
                    id="formatField"
                    fullWidth
                    source="format"
                    choices={formatChoices}
                    label="Class Format"
                    placeholder="Select"
                    validate={[required()]}
                    data-testid="formatField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <NumberInput
                    fullWidth
                    source="host_institution_credit_amount"
                    label="Host University Credit Amount"
                    min={0}
                    onChange={handleHostInstitutionCreditAmount}
                    placeholder="Enter"
                    validate={[required()]}
                    data-testid="hostInstitutionCreditAmount"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextField
                    label="Recommended US Credit Amount"
                    value={recommendedAmount}
                    fullWidth
                    placeholder="Enter"
                    InputProps={{
                      readOnly: true,
                    }}
                    className={classes.inputBase}
                    variant="filled"
                    data-testid="recommendedUsCreditAmountField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12}>
                  <TextInput
                    minRows={3}
                    fullWidth
                    source="description"
                    label="Class Description"
                    placeholder="Enter"
                    multiline
                    validate={[required()]}
                    data-testid="descriptionField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectArrayInput
                    id="levelField"
                    fullWidth
                    source="level"
                    choices={levelChoices}
                    label="Class Level"
                    placeholder="Select"
                    validate={[required()]}
                    data-testid="levelField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <NullableBooleanInput
                    className={classes.intensiveField}
                    source="intensive"
                    label="Intensive"
                    placeholder="Select"
                    validate={[required()]}
                    data-testid="intensiveField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <NumberInput
                    fullWidth
                    source="cost"
                    label="Class Cost"
                    min={0}
                    placeholder="Enter"
                    data-testid="costField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <NumberInput
                    fullWidth
                    source="price"
                    label="Class Price"
                    min={0}
                    placeholder="Enter"
                    data-testid="priceField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectInput
                    id="languageOfInstruction"
                    fullWidth
                    choices={languageOfInstructionChoices}
                    source="language_of_instruction"
                    label="Language of Instruction"
                    placeholder="Select"
                    data-testid="languageOfInstruction"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectInput
                    id="languageLevelRequirement"
                    fullWidth
                    choices={languageLevelRequirementChoices}
                    source="language_of_level_requirement"
                    label="Language Level Requirement"
                    placeholder="Select"
                    data-testid="languageLevelRequirement"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextInput
                    fullWidth
                    source="prerequisites"
                    label="Prerequisites"
                    placeholder="Enter"
                    data-testid="prerequisitesField"
                    validate={[maxLength(LARGE_PSQL_STRING_LENGTH)]}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextInput
                    fullWidth
                    source="corequisites"
                    label="Corequisites"
                    placeholder="Enter"
                    data-testid="corequisitesField"
                    validate={[maxLength(LARGE_PSQL_STRING_LENGTH)]}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextInput
                    fullWidth
                    source="required_texts"
                    label="Required Texts"
                    placeholder="Enter"
                    data-testid="requiredTextsField"
                    validate={[maxLength(DEFAULT_PSQL_STRING_LENGTH)]}
                  />
                </Grid>
                {RenderSyllabus()}
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextInput
                    fullWidth
                    source="host_institution_url_syllabus"
                    label="Host University URL Syllabus"
                    placeholder="Enter"
                    data-testid="hostInstitutionSyllabusField"
                    type="url"
                    validate={validateUrl}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <TextInput
                    fullWidth
                    source="link_text"
                    label="Link Text"
                    placeholder="Enter"
                    data-testid="linkTextField"
                    validate={[maxLength(1024)]}
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <CustomAutocomplete
                    id="cip_codes"
                    fullWidth
                    multiple
                    label="Area of Study"
                    resource="cip_codes"
                    sortField="cip_title"
                    optionId="cip_code"
                    optionLabel="cip_title"
                    selectedOptions={record.cip_codes}
                    data-testid="cipCodeField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <NumberInput
                    fullWidth
                    source="contact_hours"
                    label="Class Contact Hours"
                    min={0}
                    placeholder="Enter"
                    data-testid="contactHoursField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectInput
                    id="classroomSizeField"
                    fullWidth
                    source="classroom_size"
                    choices={classroomSizeChoices}
                    label="Classroom Size"
                    placeholder="Select"
                    data-testid="classroomSizeField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={6}>
                  <SelectArrayInput
                    fullWidth
                    source="classroom_peers"
                    choices={classroomPeersChoices}
                    label="Classroom Peers"
                    placeholder="Select"
                    validate={[required()]}
                    data-testid="classroomPeersField"
                  />
                </Grid>
                <Grid item className={classes.gridItem} xs={12} md={12}>
                  <Grid item className={classes.gridItem} xs={12} md={6}>
                    <ProgramSelector programs={programs as Program[]} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <ClassFormToolbar handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
          </Card>
        </Box>
      )}
    />
  )
}

export default ClassForm
