import React, { useState } from 'react'
import DownloadIcon from '@material-ui/icons/GetApp'
import { Button, useDataProvider, useNotify, Record, useListContext } from 'react-admin'

import { useAsync } from 'hooks/useAsync'
import { FAILURE_MESSAGE_EXPORT } from 'utils/constants'

const BULK_EXPORT_QUERY_LIMIT = 100

type CustomExportButtonProps = {
  resource: string
  exporter: (records: Record[]) => any[]
}

const CustomExportButton: React.FC<CustomExportButtonProps> = ({ resource, ...props }) => {
  const [disabled, setDisabled] = useState<boolean>(false)

  const { run } = useAsync()
  const { total } = useListContext()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const DOWNLOADING_MESSAGE = `Downloading all the ${resource} records...`

  const handleOnClick = async () => {
    setDisabled(true)
    notify(DOWNLOADING_MESSAGE, { type: 'info', autoHideDuration: 2000 })

    try {
      await run(dataProvider.exportRecords(resource, { limit: BULK_EXPORT_QUERY_LIMIT, total }))
    } catch (err) {
      notify(FAILURE_MESSAGE_EXPORT, { type: 'warning', autoHideDuration: 2000 })
    }
    setDisabled(false)
  }

  return (
    <Button onClick={handleOnClick} disabled={disabled} label="Export" data-testid={`${resource}-export-button`}>
      <DownloadIcon />
    </Button>
  )
}

export default CustomExportButton
