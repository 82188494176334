import * as React from 'react'
import { Edit } from 'react-admin'
import HostInstitutionForm from '../HostInstitutionForm'

const HostInstitutionEdit: React.FC = props => {
  const transform = (data: any) => {
    const newData = { ...data }
    delete newData.country
    delete newData.source_system
    delete newData.credit_unit

    return {
      ...newData,
    }
  }

  return (
    <Edit {...props} title="Edit Host Institution" component="div" transform={transform}>
      <HostInstitutionForm />
    </Edit>
  )
}

export default HostInstitutionEdit
