import { stringify } from 'query-string'
import { GetListParams, GetOneParams, UpdateParams, CreateParams } from 'react-admin'
import axios from 'axios'

import { DEFAULT_SORT_ORDER, LEARNING_SERVICE_ENDPOINT } from 'utils/constants'
import { prepFilters } from 'utils/filters'

class HostInstitutionProvider {
  public baseUrl: string
  private defaultSortField: string

  public constructor() {
    this.baseUrl = LEARNING_SERVICE_ENDPOINT
    this.defaultSortField = 'name'
  }

  public getList = async (resource: string, params: GetListParams) => {
    const { pagination, sort, filter } = params
    const { page, perPage } = pagination
    const { field = this.defaultSortField, order = DEFAULT_SORT_ORDER } = sort

    const query = {
      ...params.filter,
      skip: (page - 1) * perPage,
      limit: perPage,
      field,
      order,
      ...prepFilters(filter),
    }

    const url = `${this.baseUrl}/${resource}/?${stringify(query)}`

    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getOne = async (resource: string, params: GetOneParams) => {
    const url = `${this.baseUrl}/${resource}/${params.id}`

    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public create = async (resource: string, params: CreateParams) => {
    const { data: body } = params

    const url = `${this.baseUrl}/${resource}/`

    return axios
      .post(url, body)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public update = async (resource: string, params: UpdateParams) => {
    const { data: body } = params
    const url = `${this.baseUrl}/${resource}/${body.id}`

    return axios
      .put(url, body)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public archive = async (resource: string, params: UpdateParams) => {
    const { id } = params
    const url = `${this.baseUrl}/${resource}/archive/${id}`

    return axios
      .put(url, {})
      .then(response => {
        if (response.status !== 204) {
          return Promise.reject(response.statusText)
        }

        return Promise.resolve({ data: {} })
      })
      .catch(err => Promise.reject(err))
  }
}

export default HostInstitutionProvider
