export type FormatTypes = 'Virtual' | 'In-person' | 'Hybrid'

export type Format = {
  id: FormatTypes
  name: FormatTypes
}

export const formatChoices: Format[] = [
  { id: 'Virtual', name: 'Virtual' },
  { id: 'In-person', name: 'In-person' },
  { id: 'Hybrid', name: 'Hybrid' },
]

export type LevelTypes = 'Undergraduate' | 'Professional Development' | 'Graduate' | 'High School'

export type Level = {
  id: LevelTypes
  name: LevelTypes
}

export const levelChoices: Level[] = [
  { id: 'Undergraduate', name: 'Undergraduate' },
  { id: 'Professional Development', name: 'Professional Development' },
  { id: 'High School', name: 'High School' },
  { id: 'Graduate', name: 'Graduate' },
]

export type LanguageLevelRequirementTypes =
  | ''
  | 'No Language Level Required'
  | 'Beginner'
  | 'Intermediate'
  | 'Advanced'
  | 'All Levels'

export type LanguageLevelRequirement = {
  id: LanguageLevelRequirementTypes
  name: LanguageLevelRequirementTypes
}

export const languageLevelRequirementChoices: LanguageLevelRequirement[] = [
  { id: '', name: 'No Language Level Required' },
  { id: 'Beginner', name: 'Beginner' },
  { id: 'Intermediate', name: 'Intermediate' },
  { id: 'Advanced', name: 'Advanced' },
  { id: 'All Levels', name: 'All Levels' },
]

export type ClassroomSizeTypes = '0-10' | '11-30' | '31-50' | '50+'

export type ClassroomSize = { id: ClassroomSizeTypes; name: ClassroomSizeTypes }

export const classroomSizeChoices: ClassroomSize[] = [
  { id: '0-10', name: '0-10' },
  { id: '11-30', name: '11-30' },
  { id: '31-50', name: '31-50' },
  { id: '50+', name: '50+' },
]

export type ClassroomPeersTypes = 'International' | 'API Students' | 'US Students' | 'Locals'

export type ClassroomPeers = {
  id: ClassroomPeersTypes
  name: ClassroomPeersTypes
}

export const classroomPeersChoices: ClassroomPeers[] = [
  { id: 'International', name: 'International' },
  { id: 'API Students', name: 'API Students' },
  { id: 'US Students', name: 'US Students' },
  { id: 'Locals', name: 'Locals' },
]

export type StatusTypes = 'Active' | 'Inactive' | 'Draft' | 'Archive' | 'Needs Review'

export type Status = {
  id: StatusTypes
  name: StatusTypes
}

export const statusChoices: Status[] = [
  { id: 'Active', name: 'Active' },
  { id: 'Inactive', name: 'Inactive' },
  { id: 'Draft', name: 'Draft' },
  { id: 'Needs Review', name: 'Needs Review' },
]

export type LanguageOfInstructionTypes =
  | ''
  | 'No Language of Instruction'
  | 'English'
  | 'Spanish'
  | 'French'
  | 'Italian'
  | 'Arabic'
  | 'Polish'
  | 'Catalan'
  | 'Portuguese'
  | 'Latin'
  | 'Dzongkha'
  | 'Chinese'
  | 'Czech'
  | 'German'
  | 'Croatian'
  | 'Greek'
  | 'Hungarian'
  | 'Japanese'
  | 'Korean'

export type LanguageOfInstruction = {
  id: LanguageOfInstructionTypes
  name: LanguageOfInstructionTypes
}

export const languageOfInstructionChoices: LanguageOfInstruction[] = [
  { id: '', name: 'No Language of Instruction' },
  { id: 'English', name: 'English' },
  { id: 'Spanish', name: 'Spanish' },
  { id: 'French', name: 'French' },
  { id: 'Italian', name: 'Italian' },
  { id: 'Arabic', name: 'Arabic' },
  { id: 'Polish', name: 'Polish' },
  { id: 'Catalan', name: 'Catalan' },
  { id: 'Portuguese', name: 'Portuguese' },
  { id: 'Latin', name: 'Latin' },
  { id: 'Dzongkha', name: 'Dzongkha' },
  { id: 'Chinese', name: 'Chinese' },
  { id: 'Czech', name: 'Czech' },
  { id: 'German', name: 'German' },
  { id: 'Croatian', name: 'Croatian' },
  { id: 'Korean', name: 'Korean' },
  { id: 'Greek', name: 'Greek' },
  { id: 'Hungarian', name: 'Hungarian' },
  { id: 'Japanese', name: 'Japanese' },
]

export type SourceSystem = {
  API_CMS: 'API CMS'
  ACADEUM: 'Acadeum'
  CRAFT: 'CRAFT'
}

export const sourceSystem: SourceSystem = {
  API_CMS: 'API CMS',
  ACADEUM: 'Acadeum',
  CRAFT: 'CRAFT',
}
