import * as React from 'react'
import {
  FormWithRedirect,
  Toolbar,
  required,
  SaveButton,
  FormWithRedirectRenderProps,
  ToolbarProps,
  RedirectionSideEffect,
  FormWithRedirectSave,
  Record,
} from 'react-admin'
import { Box, Grid, Card, CardContent } from '@material-ui/core'

import { GridTextInput } from 'components/Forms'

const ProgramFormToolbar: React.FC<ToolbarProps> = ({ handleSubmitWithRedirect }) => (
  <Toolbar handleSubmitWithRedirect={handleSubmitWithRedirect}>
    <SaveButton label="Save" redirect="list" submitOnEnter data-testid="saveButton" />
  </Toolbar>
)

type ProgramFormProps = {
  record?: Record
  save?: FormWithRedirectSave
  saving?: boolean
  redirect?: RedirectionSideEffect
}

const ProgramForm: React.FC<ProgramFormProps> = props => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: FormWithRedirectRenderProps) => {
        const { handleSubmitWithRedirect } = formProps
        return (
          <Box maxWidth="100%">
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <GridTextInput
                    source="name"
                    label="Program Name"
                    validate={[required()]}
                    testId="programNameField"
                  />
                  <GridTextInput source="salesforce_id" label="Salesforce ID" testId="programSalesforceIdField" />
                  <GridTextInput source="url" label="URL" testId="programUrlField" />
                </Grid>
              </CardContent>
              <ProgramFormToolbar handleSubmitWithRedirect={handleSubmitWithRedirect} />
            </Card>
          </Box>
        )
      }}
    />
  )
}

export default ProgramForm
