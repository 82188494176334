import * as React from 'react'
import { Create } from 'react-admin'
import HostInstitutionForm from '../HostInstitutionForm'

const HostInstitutionCreate: React.FC = props => {
  const transform = (data: any) => {
    const newData = { ...data }
    delete newData.country
    delete newData.source_system
    delete newData.credit_unit

    return {
      ...newData,
    }
  }

  return (
    <Create {...props} title="Create Host Institution" component="div" transform={transform}>
      <HostInstitutionForm />
    </Create>
  )
}

export default HostInstitutionCreate
