import * as React from 'react'
import { TextInput } from 'react-admin'
import FilterAutocompleteInput from 'components/FilterAutocomplete'
import { makeStyles } from '@material-ui/styles'
import SearchQueryFilter from 'components/SearchQueryFilter'

const useStyles = makeStyles({
  filter: {
    minWidth: '200px !important',
  },
  autoCompleteFilter: {
    maxWidth: '200px !important',
    '& input': {
      maxHeight: '15px',
    },
  },
  searchInput: {
    width: '100%',
  },
  searchLabel: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'initial',
    '& svg': {
      marginRight: '5px',
    },
  },
  selectBox: {
    '& button': {
      position: 'absolute',
      top: '0px',
      right: '0px',
    },
  },
})

interface StyledAutocompleteProps {
  id: string
  dataResource: string
  label: string
  source: string
  sortField?: string
  sortOrder?: string
  perPage?: number
}

const StyledAutocomplete: React.FC<StyledAutocompleteProps> = ({
  id,
  dataResource,
  label,
  source,
  sortField,
  sortOrder,
  perPage,
}) => {
  const classes = useStyles()
  return (
    <FilterAutocompleteInput
      perPage={perPage}
      sortField={sortField}
      sortOrder={sortOrder}
      label={label}
      dataResource={dataResource}
      source={source}
    />
  )
}

interface StyledInputProps {
  id: string
  label: string
  source: string
}

const StyledInput: React.FC<StyledInputProps> = ({ id, label, source }) => {
  const classes = useStyles()
  return <TextInput id="city-search" source={source} className={classes.filter} label={label} variant="filled" />
}

const hostInstitutionFilters = [
  <SearchQueryFilter key="search-query-filter" alwaysOn source="Search" />,
  <StyledInput key="search-city-filter" id="city-search" label="City" source="city" />,
  <StyledAutocomplete
    key="search-credit-unit-id-filter"
    id="credit-units-filter"
    dataResource="credit_units"
    label="Credit Unit"
    source="credit_unit_id"
    perPage={100}
  />,
  <StyledAutocomplete
    key="search-country-id-filter"
    id="country-filter"
    dataResource="countries"
    label="Country"
    source="country_id"
    sortField="name"
    sortOrder="ASC"
    perPage={300}
  />,
]

export default hostInstitutionFilters
